<template>
  <div class="integraInfo-container">
    <div class="integraInfo-left">
      <!-- 搜索框 -->
      <div class="tree-search">
        <v-input
          v-model="filterText"
          prefix-icon="el-icon-search"
          placeholder="请输入名称"
          :width="220"
        />
      </div>

      <!-- 控件树 -->
      <div class="tree-wrap">
        <div class="tree-group">
          <v-tree
            :data="treeData"
            :defaultProps="treeProps"
            :lazy="true"
            :expand-on-click-node="false"
            :filterText="filterText"
            :filterNodeMethod="filterNodeMethod"
            @loadData="treeLoad"
            @nodeClick="selectNode"
          ></v-tree>
        </div>
      </div>
    </div>
    <div class="integraInfo-right">
      <div class="system-wrap">
        <div class="system-info">
          <div class="system-center">
            <div>积分体系信息</div>
            <div>运营主体：{{ sysIntegralInfo.regionName }}</div>
          </div>
        </div>
        <div class="system-right">
          <div class="using" v-if="sysIntegralInfo.regionName">{{ sysIntegralInfo.configFlag?'使用中':'停止使用' }}</div>
        </div>
      </div>

      <div class="subject-info">
        <div class="subject-left">
        <div class="username">
          <div>用户可用积分</div>
          <div>{{ sysIntegralInfo.availableIntegral }}</div>
        </div>
        <div class="username">
          <div>累计发放积分</div>
          <div>{{ sysIntegralInfo.totalIntegral }}</div>
        </div>
        <div class="username">
          <div>累计消耗积分</div>
          <div>{{ sysIntegralInfo.usedIntegral }}</div>
        </div>
        <div class="username">
          <div>过期积分</div>
          <div>{{ sysIntegralInfo.overIntegral }}</div>
        </div>
      </div>
        <div class="subject-right">
          <v-button text="积分发放记录" permission="grant" @click="grantRecord()"></v-button>
          <v-button text="积分消耗记录" permission="expend" @click="expendRecord()"></v-button>
        </div>
      </div>
      <div class="line-style"><span>积分基本信息</span></div>
      <div class="basic-info">
        <div class="panel-wrap" v-if="sysIntegralInfo.integralName">
          <div><span>积分名称</span>{{ sysIntegralInfo.integralName }}</div>
          <div><span>分值参考</span>{{ sysIntegralInfo.intNum }}积分={{ sysIntegralInfo.unit }}元</div>
          <div><span>开通时间</span>{{ sysIntegralInfo.intime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getListByPage, getRegionTreeURL } from "./api";
import { vTree } from "components/control/index";
export default {
  name: "GroupIntegraInfo",
  components: {
    vTree,
  },
  data() {
    return {
      filterText: "",
      treeData: [],
      treeProps: {
        children: "children",
        label: "orgName",
        isLeaf: (data, node) => {
          return true;
        },
      },
      sysIntegralInfo:{},
      regionId: ''
    };
  },
  mounted() {
    // this.handleGetInfoById();
  },
  methods: {
    getSysIntegralInfo(tenantId) {
      // 获取用户基础信息
      let params = {
        // this.$store.state.userInfo.regionId
        regionId: tenantId
      };
      this.$axios.get(getListByPage, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.sysIntegralInfo = res.data;
        } else{
          this.sysIntegralInfo = {}
        }
      });
    },
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },
    getProjectTree(id) {
      // let params = {}
      // id && (params.parentRegionId = id)
      return new Promise((resolve, reject) => {
        this.$axios.get(getRegionTreeURL).then((res) => {
          if (res.status === 100) {
            let { data } = res;
            resolve(data);
          }
        });
      });
    },
    async treeLoad(node, resolve) {
      // let { id } = node.data
      let list = await this.getProjectTree();
      console.log(list, "list");
      setTimeout(() => {
        return resolve(list);
      }, 500);
    },
    selectNode(data) {
      console.log(data, "999");
      let { tenantId } = data;
      this.regionId = tenantId
      this.getSysIntegralInfo(tenantId)
      // this.$nextTick(() => {
      //   this.$set(this.searchParams, 'parentRegionId', id)
      //   this.$set(this.searchParams, 'levelType', 1)
      //   this.$refs.list.searchData()
      //   this.$set(this.searchParams, 'levelType', undefined)
      // })
    },
    grantRecord(){
      this.$router.push({
        path: "integraSendRecords",
        query: {
          regionId: this.regionId
        },
      });
    },
    expendRecord(){
      this.$router.push({
        path: "integraExpendRecords",
        query: {
          regionId: this.regionId
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.integraInfo-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  .integraInfo-left {
    padding: 0 20px;
    box-sizing: border-box;
    .tree-wrap {
      margin-top: 20px;
      height: calc(100% - 93px);
      width: 240px;
      .tree-group {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        overflow: auto;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }
  .integraInfo-right {
    flex: 1;
    .system-wrap {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .system-info {
        flex: 1;
        border: 1px solid rgba(242, 242, 242, 1);
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(251, 251, 251, 1);
        color: #666;
        font-size: 14px;
      }
      .system-right {
        display: flex;
        align-items: center;
        padding: 0 20px;
        .using {
          color: #67c23a;
          background-color: #d9f7cb;
          border: 1px solid #67c23a;
          padding: 6px;
          border-radius: 4px;
          width: 110px;
        }
      }
    }

    .subject-info {
      width: 100%;
      padding: 10px 20px;
      // margin-left: 20px;
      box-sizing: border-box;
      // background-color: rgba(251, 251, 251, 1);
      display: flex;
      justify-content: space-between;
      // border: 1px solid rgba(242, 242, 242, 1);
      border-radius: 4px;
      margin-bottom: 20px;
      .subject-left {
        display: flex;
        align-items: center;
        height: 100px;
        font-size: 20px;
        font-weight: bold;
        color: #666;
        justify-content: space-around;
        flex: 1;
        .username {
          flex: 1;
        }
      }
      .subject-right {
        > div {
          display: block;
          margin-top: 12px;
        }
      }
    }
    .basic-info {
      .panel-wrap {
        padding: 50px 30px;
        text-align: left;
        > div {
          padding-bottom: 20px;
          font-size: 14px;
          color: #333;
          span {
            padding-right: 50px;
          }
        }
      }
    }
    .line-style {
      background-color: rgba(251, 251, 251, 1);
      padding: 10px 30px;
      text-align: left;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -10px;
        background: #409eff;
      }
    }
    .btn-box {
      ::v-deep .el-button {
        width: 100%;
        margin-top: 5px;
        display: block;
      }
    }
  }

  ::v-deep .el-tooltip {
    padding-right: 10px;
  }
  ::v-deep .el-icon-question {
    position: absolute;
    top: 15px;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
